@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;700;800&family=Poppins&display=swap");

.ff_karla {
  font-family: "Karla", sans-serif;
}

.ff_poppins {
  font-family: "poppins";
}

* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-decoration: none !important;
  box-sizing: border-box;

}

:root {
  /* ---------------- fs--------------- */
  --fs-xsm: 10px;
  --fs-sm: 16px;
  --fs-md: 18px;
  --fs-lg: 20px;
  --fs-xl: 32px;
  --fs-xxl: 73px;
  /* ----------------- fw --------------- */
  --fw-xsm: 400;
  --fw-sm: 500;
  --fw-md: 700;
  --fw-lg: 800;
  /* ------------------ bg ---------------- */
  --bg-blue: #0c2955;
}

.fs_10 {
  font-size: var(--fs-xsm);
}

.fs_16 {
  font-size: var(--fs-sm);
}

.fs_18 {
  font-size: var(--fs-md);
}

.fs_20 {
  font-size: var(--fs-lg);
}

.fs_32 {
  font-size: var(--fs-xl);
}

.fs_73 {
  font-size: var(--fs-xxl);
}

.fw_400 {
  font-weight: var(--fw-xsm);
}

.fw_500 {
  font-weight: var(--fw-sm);
}

.fw_700 {
  font-weight: var(--fw-md);
}

.fw_800 {
  font-weight: var(--fw-lg);
}

.bg_blue {
  background-color: var(--bg-blue);
}

.clr_gray {
  color: #777777;
}

.pt_30 {
  padding-top: 30px;
}

.card-1 {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 16px;
  transition: all ease-in-out 400ms !important;
}

.card-2 {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 16px 16px 35px 16px;
  transition: all ease-in-out 400ms !important;
}

.text_blue {
  color: #0c2955;
}

.card-3 {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 26px 16px 42px 20px;
  transition: all ease-in-out 400ms !important;
}

.card-1:hover {
  background-color: #0c2955 !important;
  color: #ffffff !important;
}

.card-2:hover {
  background-color: #0c2955 !important;
  color: #ffffff !important;
}

.card-3:hover {
  background-color: #0c2955 !important;
  color: #ffffff !important;
}

.white_space {
  white-space: nowrap;
}

.max_hight {
  max-height: 200px;
  direction: rtl;
}

.position-abso {
  position: absolute;
  top: 0;
  left: 30px;
  /* animation: translate 5s linear infinite; */
}

.animation_translate {
  /* animation: translate 5s linear infinite; */
}

@keyframes translate {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0px);
  }
}

.scrool {
  direction: ltr;
}

::-webkit-scrollbar {
  border-radius: 40px !important;
  width: 10px;
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  border-radius: 40px !important;
  width: 10px;
  background-color: #c8c7c7;
}

.box {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 100%;
  padding: 10px 10px 10px 10px;
  transition: all 400ms ease-in-out;
}

.carousel-indicators {
  bottom: -25px !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #0c2955 !important;
}

.img {
  transition: all 400ms ease-in-out;
}

.request_btn {
  background: #0c2955;
  border-radius: 4px;
  border: 1px solid #0c2955;
  padding: 10px 135px;
  transition: all ease-in-out 400ms;
}

.request_btn:hover {
  background: transparent;
  color: #0c2955 !important;
  box-shadow: 4px 9px 10px #0c2955a8;
}

.bg_img {
  background-image: url(./assets//image/png/bg_img.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_login {
  background-image: url(./assets//image/png/login_img.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.outline_none {
  outline: none;
}

::placeholder {
  color: #cccccc;
}

.form_box {
  background: #ffffff;
  border-radius: 9px 9px 9px 9px;
  max-width: 447px;
  padding: 25px 20px;
}

.box_1 {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  max-width: 768px;
  padding: 30px;
}

.item-1 {
  width: 135.28px;
  height: 61.93px;
  object-fit: contain;
}

.item-2 {
  width: 134.68px;
  height: 45.7px;
  object-fit: contain;
}

.item-3 {
  width: 122.53px;
  height: 36.65px;
  object-fit: contain;
}

.item-4 {
  width: 130.34px;
  height: 36.04px;
  object-fit: contain;
}

.item-5 {
  width: 145.51px;
  height: 42.12px;
  object-fit: contain;
}

.item-6 {
  width: 122.53px;
  height: 50.87px;
  object-fit: contain;
}

.my-col {
  width: 12% !important;
}

.box:hover img {
  transform: scale(1.04);
}

.carousel-control-next,
.carousel-control-prev {
  bottom: 30px !important;
}

/* 
.star_animate {
  animation-name: star_animate;
  animation-duration: 3s;
  animation-timing-function: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes star_animate {
  0% {
    transform: scale(0.4);
  }

  100% {
    transform: scale(1.1);
  }
} */

.position-abso1 {
  position: absolute;
  /* top: 100px; */
  right: 30px;
  bottom: 50px !important;
}

.position-abso2 {
  position: absolute;
  top: 130px;
  right: 70px;
  /* bottom: 50px !important; */
}

.position-abso-new {
  position: absolute;
  top: 30px;
  right: 10%;
}

.border {
  border: 1px solid #000000 !important;
  background-color: #000000 !important;
  transform: rotate(180deg);
}

.form {
  background: #ffffff;
  border-radius: 9px;
  border: 1px solid #cccccc;
}

@media (max-width: 576px) {
  :root {
    /* ---------------- fs--------------- */
    --fs-xsm: 10px;
    --fs-sm: 13px;
    --fs-sm: 14px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 20px;
    --fs-xxl: 40px;
  }

  .w_100 {
    width: 100%;
  }

  .my_col1 {
    width: 50%;
  }

  .overflow_auto {
    overflow: auto;
  }

  .capital_hover {
    height: 100% !important;
  }

  .capital_hover .tooltiptext {
    visibility: hidden;
    width: 600px;
    background-color: #0c2955 !important;
    color: white !important;
    border-radius: 6px;
    padding: 5px 5px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    /* top: -5px; */
    bottom: -0px;
    left: -0px;

  }

  .request_btn {
    background: #0c2955;
    border-radius: 4px;
    border: 1px solid #0c2955;
    padding: 10px 60px;
    transition: all ease-in-out 400ms;
  }
}

@media (max-width: 1200px) {
  .min_vh_xl_100 {
    min-height: 576px;
  }

  .w-50 {
    width: 50%;
  }

  .my-col {
    width: 33.33% !important;
  }
}

@media (min-width: 576px) {
  :root {
    /* ---------------- fs--------------- */
    --fs-xsm: 10px;
    --fs-sm: 12px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 32px;
    --fs-xxl: 45px;
  }

  .my_col1 {
    width: 33, 33%;
  }

  .my-col {
    width: 33.33% !important;
  }

  .overflow_auto {
    overflow: auto;
  }
}

@media (min-width: 768px) {
  :root {
    /* ---------------- fs--------------- */
    --fs-xsm: 10px;
    --fs-sm: 12px;
    --fs-md: 12px;
    --fs-lg: 20px;
    --fs-xl: 32px;
    --fs-xxl: 50px;
  }

  .my_col1 {
    width: 20%;
  }

  .my-col {
    width: 12% !important;
  }

  .max_width {
    max-width: 1000px;
  }

  .card-3 {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: 20px 10px 20px 10px;
  }

  .item-1 {
    width: 101.28px;
    height: 45.93px;
  }

  .item-2 {
    width: 88.68px;
    height: 30.7px;
  }

  .item-3 {
    width: 88.53px;
    height: 28.65px;
  }

  .item-4 {
    width: 88.34px;
    height: 22.04px;
  }

  .item-5 {
    width: 83.51px;
    height: 34.12px;
  }

  .item-6 {
    width: 88.53px;
    height: 34.87px;
  }
}

@media (min-width: 992px) {
  :root {
    /* ---------------- fs--------------- */
    --fs-xsm: 10px;
    --fs-sm: 14px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 32px;
    --fs-xxl: 58px;
  }

  .card-3 {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: 26px 16px 57px 20px;
  }

  .overflow_auto {
    overflow: visible;
  }

  .item-1 {
    width: 135.28px;
    height: 61.93px;
  }

  .item-2 {
    width: 134.68px;
    height: 45.7px;
  }

  .item-3 {
    width: 122.53px;
    height: 36.65px;
  }

  .item-4 {
    width: 155.34px;
    height: 36.04px;
  }

  .item-5 {
    width: 145.51px;
    height: 42.12px;
  }

  .item-6 {
    width: 122.53px;
    height: 50.87px;
  }
}

@media (min-width: 1200px) {
  :root {
    /* ---------------- fs--------------- */
    --fs-xsm: 10px;
    --fs-sm: 14px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 32px;
    --fs-xxl: 65px;
  }

  .min_vh_xl_100 {
    min-height: 100vh;
  }
}

@media (min-width: 1400px) {
  :root {
    /* ---------------- fs--------------- */
    --fs-xsm: 10px;
    --fs-sm: 16px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 32px;
    --fs-xxl: 73px;
  }
}

/* Back to top */
.back-to-top {
  position: fixed;
  bottom: 3%;
  right: 20px;
  z-index: 9999;
  font-size: var(--font-sm);
  color: var(--white);
  border: 1px solid white;
  background-color: var(--bg-blue);
  border-radius: 12px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.cursor_pointer {
  cursor: pointer;
}

.text_align_justify {
  text-align: justify !important;
}

.hci_img,
.capital_text,
.advisors,
.info_btn,
.hafeez_data,
.hafeez_logos,
.milestone_card,
.brands_hover {
  visibility: hidden;
}

.milestone_card {
  visibility: hidden;
}

.swal-icon--success__line {
  background-color: #0c2955;
}

.swal-icon--success__ring {
  border: 4px solid #0c2955;
}

.swal-button {
  background-color: #0c2955;
}

.swal-footer {
  text-align: center;
}

.swal-title {
  font-family: "Karla", sans-serif;
}

.swal-text {
  font-family: "Karla", sans-serif;
}