.bg_header {
  background-image: url(./assets/image/png/headimage.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.mw_770 {
  max-width: 770px;
}


.info_btn {
  background: #0C2955;
  border-radius: 4px;
  padding: 10px 42px;
  border: 1px solid #0C2955;
  transition: all ease-in-out 300ms;
}

.info_btn:hover {
  background: transparent;
  color: #0C2955 !important;
  box-shadow: 4px 9px 10px #0c2955a8;
}



/* loading */
.overflow_hidden_preloder {
  overflow: hidden;
  position: relative;
}

.loding_position {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.animation_loding {
  animation: loading_animate 1s alternate linear infinite;
}

@keyframes loading_animate {
  0% {
    transform: scale(1);
  }

  0% {
    transform: scale(1.5);
  }
}

.capital_hover .tooltiptext {
  visibility: hidden;
  width: 425px;
  background-color: white;
  color: black;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  /* top: -5px; */
  bottom: -150px;
  left: 0;
}

.capital_hover .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: white;
  color: black;
  border-radius: 6px;
  padding: 5px 20px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  /* top: 100px; */
  bottom: 100px;
  left: -100px;
}

.my_col1 {
  width: 20%;
}

.tooltiptext {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.capital_hover:hover .tooltiptext {
  visibility: visible;
}


.capital_hover {
  position: relative;
  display: inline-block;

}


.advisors {
  transition: all ease-in-out 300ms !important;

}

.advisors:hover {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
}

.wh_advisors {
  Width: 146.97px;
  Height: 86.94px;
  transform: all ease-in-out 300ms;
}

.wh_assets {
  Width: 178.97px;
  Height: 86.94px;
}

.wh_cleantech {
  Width: 159.97px;
  Height: 86.94px;
}

.wh_homes {
  Width: 169.97px;
  Height: 86.94px;
}

.wh_properties {
  Width: 168.97px;
  Height: 86.94px;
}

/* .brands_hover {
  filter: grayscale(100%);
  transition: all 300ms linear;
}

.brands_hover:hover {
  filter: unset;
} */